import { string, shape, object, bool } from 'vue-types';

export const moduleShape = {
  type: string().required,
  trigger: object(),
  bgFill: bool().def(false),
  marginBottom: bool().def(true),
  padding: bool().def(false),
};

export const moduleType = shape(moduleShape).loose;
