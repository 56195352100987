
import { arrayOf, bool, number, shape, string } from 'vue-types';
import { Power0 } from 'gsap';

import hasScrollTriggers from '@/mixins/hasScrollTriggers';
import { moduleShape } from '@/types/module';
import { visual } from '@/types/visual';

export default {
    mixins: [hasScrollTriggers],
    props: {
        ...moduleShape,
        // front: bool().def(false),
        items: arrayOf(
            shape({
                visual,
                classes: string(),
                front: bool().def(false),
                alignEnd: bool().def(false),
                parallaxRatio: number().def(1),
            }).loose
        ),
    },
    methods: {
        getItemClasses(item) {
            return [item.classes, item.front && 'front', item.alignEnd && 'self-end'];
        },
        /**
         * Use scrolltriggers to translate each of the images for a parallax-ish effect.
         */
        setupScrollTriggers() {
            this.$ScrollTrigger.matchMedia({
                '(max-width: 1023px)': () => {
                    this.setupMobileParallax();
                },
                '(min-width: 1024px)': () => {
                    this.setupDesktopParallax();
                },
            });
        },
        setupMobileParallax() {
            const visuals = this.$refs.visual;

            visuals.forEach((visual, index) => {
                if (index === 0) {
                    return;
                }

                const visualEl = visual.$el;

                const tl = this.$gsap.timeline({
                    scrollTrigger: {
                        scroller: this.scrollingElement,
                        trigger: this.$el,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    defaults: {
                        ease: Power0.easeNone,
                    },
                });

                tl.to(visualEl, {
                    yPercent: -50,
                    ease: Power0.easeNone,
                });

                this.addScrollTrigger(tl);
            });
        },
        setupDesktopParallax() {
            this.items.forEach((item, index) => {
                if (item.parallax === false) {
                    return;
                }

                const visual = this.$refs.visual[index];
                const visualEl = visual.$el;
                const parallaxRatio = item.parallaxRatio || 1;

                const tl = this.$gsap.timeline({
                    scrollTrigger: {
                        scroller: this.scrollingElement,
                        trigger: this.$el,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: true,
                        invalidateOnRefresh: true,
                    },
                    defaults: {
                        ease: Power0.easeNone,
                    },
                });

                tl.to(visualEl, {
                    y: () => {
                        const moduleRect = this.$el.getBoundingClientRect();
                        const imgRect = visualEl.getBoundingClientRect();
                        let ty;

                        // items that are 'alignEnd' will travel up from bottom of module
                        // default: item will travel down from top of module
                        if (item.alignEnd) {
                            ty = moduleRect.top - imgRect.top;
                        } else {
                            ty = moduleRect.bottom - imgRect.bottom;
                        }

                        // we can also pass in a ratio, in order not to travel the entire distance
                        // (or even more, by passing in a ratio > 1)
                        ty = parallaxRatio * ty;

                        return ty;
                    },
                    ease: Power0.easeNone,
                });

                this.addScrollTrigger(tl);
            });
        },
    },
};
